const pl = {
  COMMON: {
    NEXT: 'Następny ',
    NEXT_PLURAL: 'Następne ',
    TOMORROW: 'jutro',
    DONT_KNOW: 'nie wiem',
    TODAY: 'dzisiaj',
    DAY: 'dzień',
    DAYS: 'dni',
    HOUR_SHORT: 'g',
    HOUR: 'godzina',
    HOURS_2_4: 'godziny',
    HOURS: 'godzin',
    MINUTE: 'minuta',
    MINUTES_2_4: 'minuty',
    MINUTES: 'minut',
    BOOK_REVIEW: 'Recenzja książki',
    ARTICLE: 'Artykuł',
    PODCAST: 'Podcast',
    WORKSHOPS: 'Warsztaty',
    WEBINAR: 'Webinar',
    CONFERENCE: 'Konferencja',
    LECTURE: 'Wykład',
    SEARCH: {
      CLOSE: 'Zamknij',
      TIP: 'Aby otworzyć wyszukiwarkę, naciśnij ctrl + s na klawiaturze. Aby zamknąć, naciśnij esc.',
      LABEL: 'Wyszukaj',
      PLACEHOLDER: 'Wpisz temat, który Cię interesuje...',
      NO_RESULTS: 'Nie znaleziono wyników dla frazy: @1',
      LOADING: 'Trwa wyszukiwanie...',
    },
  },
  COOKIES: {
    TITLE: 'Ciasteczkowy potwór już tu był!',
    MESSAGE: 'Moja strona www wykorzystuje "ciasteczka". Szczegółowe informacje znajdziesz w mojej %%polityce zarządzania ciasteczkami%%.',
    ACCEPT: 'Ok',
    NOT_ACCEPT: 'Nie pasuje mi to',
  },
  NOT_TRANSLATED: {
    TITLE: 'Ta strona dostępna jest tylko w języku angielskim',
    MESSAGE: 'Aktualnie pracuję nad jej tłumaczeniem!',
  },
  HEADER: {
    TITLE: 'Mateusz Jabłoński - Blog, programowanie, rozwój',
  },
  FOOTER: {
    TITLE: 'Mateusz Jabłoński - Blog, programowanie, rozwój',
    FUNDS_INFO: {
      MESSAGE: '%%5% wpłat%% na ',
      INFO: ' i ze sprzedaży kursów przekazuję do fundacji ',
      ENCOURAGEMENT: 'Ciebie też zachęcam!',
      LOGO_ALT: 'Logo Fundacji Radość z Uśmiechu',
      HELP_ACTION_LABEL: 'Wspomóż fundację',
    },
    SOCIAL_MEDIA: {
      TITLE: 'Zajrzyj na moje %%social media!%%',
    },
  },
  MENU: {
    HOME: 'Home',
    BLOG: 'Blog',
    ABOUT: 'O mnie',
    WORKSHOPS: 'Warsztaty',
    PODCAST: 'Podcast',
    CONTACT: 'Kontakt',
    MEMBER_AREA: 'Strefa kursanta',
    PRIVACY_POLICY: 'Polityka prywatności',
    COOKIES: 'Informacja o cookies',
    DOCS: 'Dokumenty',
  },
  HOME: {
    HERO: {
      FREE_CONTENT_ACTION: 'Zobacz bezpłatne materiały',
      ABOUT_ACTION: 'Moja idea',
    },
    LAST_ARTICLES: 'Ostatnie %%artykuły%%',
    LAST_ARTICLES_END: {
      TITLE: 'A to tylko ostatnie artykuły!',
      MESSAGE: 'Większą dawkę wiedzy znajdziesz na stronie bloga. Zapraszam serdecznie!',
      ACTION: 'Wszystkie',
    },
    ALL_ARTICLES: 'Wszystkie moje artykuły',
    LAST_PODCASTS: 'Ostatnie %%podcasty%%',
    ALL_PODCASTS: 'Wszystkie podcasty',
    WANT_TO_BE_A_GUEST: 'Chcę wystąpić w podcaście',
    RECOMMENDED_BOOKS: 'Polecane %%książki%%',
    MORE_BOOKS: 'Więcej książek',
    LAST_WORKSHOPS: 'Aktualnie dostępne ',
    LAST_WORKSHOPS_STRONG: 'warsztaty',
    NEXT_PODCAST: 'podcast',
    NEXT_ARTICLE: 'artykuł',
    NEXT_ARTICLE_SLIDE: 'Następny artykuł',
    PREV_ARTICLE_SLIDE: 'Poprzedni artykuł',
    LISTEN_PODCAST: 'Słuchaj mojego podcastu:',
    LISTEN_PODCAST_ON: 'Słuchaj podcastu @1 na @2',
    LAST_EVENTS: 'Najbliższe %%wydarzenia%%',
    NEXT_EVENT: 'wydarzenie',
    NEXT_EVENT_IN: 'Już za @1 @2',
    NEXT_EVENT_TODAY: 'Już dzisiaj',
    BOOKS: {
      NO_LINK: 'Brak recenzji',
      WITH_LINK: 'Przejdź do recenzji',
    },
  },
  BREADCRUMBS: {
    CONTACT: 'Kontakt',
    ABOUT: 'O mnie',
    WORKSHOPS: 'Warsztaty',
    BOOKS: 'Książki',
    DOCS: 'Dokumenty',
  },
  ABOUT: {
    MOTIVATOR: {
      TITLE: 'Chciałbym być %%bardzo dobry%% w tym, co robię',
      MESSAGE: 'moje doświadczenie pokazuje mi jak wiele udało mi się osiągnąć, a także jak dużo nadal przede mną.',
    },
    DOTTED_TITLE: 'Przestrzeń %%pomiędzy znakami%%',
    LAST_CONTENT: {
      TITLE: 'Ostatnie materiały',
      TEXT: 'Poniżej znajdziesz najnowsze przygotowane przeze mnie materiały. Zapraszam do zapoznania się z nimi. Mam nadzieję, że wyciągniesz z nich dużo wartości dla siebie.',
    },
    CURRENT: {
      READ: {
        TITLE: 'Aktualnie czytam',
        NO_ACTIVITY: 'Chwilowy brak aktywności',
      },
      LISTEN: {
        TITLE: 'Ostatnio słuchałem',
        NO_ACTIVITY: 'Chwilowy brak aktywności',
      },
    },
    STORY: {
      TITLE: 'Jak to się zaczęło?',
      INTRO: 'Programowaniem zacząłem się interesować już dawno temu. Mając zaledwie 12 lat wraz z dwójką moich przyjaciół snuliśmy marzenia o wielkich projektach IT, braliśmy udział w różnych konkursach informatycznych i świetnie się przy tym bawiliśmy.',
      BLOCKQUOTE: 'Nigdy nie przypuszczałem, że programowanie stanie się moją pasją i zawodem.',
      EPISODE_1_1: 'W 2011 roku życie pokierowało moją karierę w kierunku tworzenia stron internetowych.',
      EPISODE_1_2: 'Od samego początku czułem, że się w tym odnajduję, że sprawia mi to dużo radości, a osiągane efekty popychają mnie dalej - coś kazało mi zagłębiać się w ten temat i wyciągać z niego coraz więcej dla siebie.',
      EPISODE_1_3: 'Niewątpliwie dużą rolę w moim życiu odegrali moi pierwsi mentorzy i współpracownicy.',
      EPISODE_2_1: 'Kilka lat później postanowiłem spróbować swoich sił w edukacji. Na ścieżkę mentorską wstąpiłem pod koniec 2016 roku.',
      EPISODE_2_2: 'Od tamtego czasu nieustannie staram się rozwijać nie tylko swoje umiejętności techniczne, ale również miękkie - przemawianie, pisanie, rozmawianie.',
      EPISODE_2_3: 'Zrozumiałem jak ważną rolę pełni mentor - trener podczas każdej sesji mentorskiej, warsztatów czy szkoleń. Można być świetnym programistą i nie mieć zacięcia do uczenia innych.',
      PHOTO_1_CAPTION: 'To ja wraz ze znajomymi przed II LO. Rok 2008.',
      PHOTO_2_CAPTION: 'Pierwsze kroki z Wordpress i Magento. Rok 2011.',
      PHOTO_3_CAPTION: 'Szkolenie wewnętrzne w Sonalake. Rok 2020.',
    },
  },
  ARTICLE: {
    SUMMARY: {
      TITLE: 'Z tego artykułu dowiesz się:',
    },
    META: {
      PUBLICATION_DATE: 'Data publikacji',
      LEVEL: 'Poziom',
      PODCAST: 'Podcast',
      EPISODE: 'Odcinek',
      CATEGORY: 'Kategoria',
      READING_DATE: 'Data przeczytania',
      MY_RATE: 'Moja ocena',
    },
    BUY_COFFEE: {
      TITLE: 'Podoba Ci się to co robię?',
      MESSAGE: 'Wszystkie materiały na stronie są darmowe. Jeśli uznasz je za przydatne, rozważ proszę wsparcie mnie poprzez postawienie mi kawy. ;)',
      ACTION: 'Tak, stawiam Ci kawę!',
    },
    ACTIONS: {
      READ_MORE: 'Czytaj więcej',
    },
    SOURCES: 'Źródła',
    SHARE: 'Udostępnij ten artykuł:',
    UPDATE_NOTE: {
      TITLE: 'Aktualizacja z dnia @1',
    },
    AUTHOR: {
      TITLE: 'O autorze',
      YOU_WILL_FIND_ME: 'Znajdziesz mnie'
    },
    COMMENTS: {
      TITLE: 'Komentarze',
      ADD: 'Dodaj komentarz',
      NO_NAME: 'Anonimowy autor',
      FIRST_MESSAGE: 'Jeszcze nikt nic nie napisał, ale to znaczy że... możesz być pierwszy/pierwsza.',
      COMMENTING_TITLE: 'Komentujesz artykuł',
      BACK: 'Cofnij'
    },
    RELATED: {
      TITLE: 'Powiązane treści',
      TEXT: 'Jeżeli ten artykuł Cię zainteresował sprawdź inne materiały powiązane z nim tematycznie. Poniżej znajdziesz artykuły i odcinki podcastów mojego autorstwa oraz polecane przeze mnie książki, które rozszerzają ten temat.',
    },
  },
  WORKSHOPS: {
    ACTIONS: {
      TAKE_PART: 'Weź udział',
      ALL: 'Wszystkie warsztaty',
    },
    PROGRAM: {
      TITLE: 'Program',
    },
    REVIEWS: {
      TITLE: 'Dołącz do grona zadowolonych uczestników moich warsztatów!',
      NO_REVIEWS: 'Aktualnie brak opinii o tych warsztatach.',
      ANONYMOUS_USER: 'Uczestnik warsztatów',
    },
  },
  CONTACT: {
    NEWSLETTER: {
      TITLE: 'Mój newsletter',
      MAIN_CONTENT_1: 'Dołącz bezpłatnie do mojego newslettera. Raz na dwa tygodnie otrzymasz ode mnie wiadomość z informacjami o nowych materiałach, które znajdziesz na mojej stronie. Będziesz nie tylko pierwszy, ale uzyskasz dostęp do ciekawostek, interesujących ofert i nie publikowanych wcześniej materiałów.',
      MAIN_CONTENT_2: 'Newsletter, podobnie jak większość materiałów na mojej stronie, jest całkowicie bezpłatny. Obiecuję nie spamować i wysłać to, co rzeczywiście może Ci się przydać.',
    },
  },
  POLL: {
    TITLE: 'Ankieta',
    DESCRIPTION: 'Poniższa ankieta dotyczy szkolenia "@1". Ankieta jest anonimowa. Ankieta składa się z 5 kroków. Każda odpowiedź ma dla mnie ogromną wartość, bo dzięki niej będę mógł poprawić jakość szkolenia. Dziękuję za poświęcony czas!'
  },
  PODCAST: {
    MY_PODCASTS: {
      TITLE: 'Moje podcasty',
      META: {
        SEASON: 'Sezon',
        EPISODES: 'Odcinki',
      }
    },
    GUEST_PODCASTS: {
      TITLE: 'Gościnne podcasty',
    },
    COMMON: {
      LISTEN: 'Słuchaj',
      SOURCES_TITLE_DOUBLE: 'Nasz podcast znajdziesz',
      AUTHOR: 'Autor: @1',
      COMMENTS: 'Komentarze',
      READ_MORE: 'Czytaj więcej',
      TRANSCRIPTION: 'Transkrypcja',
    },
    PLAYER: {
      LOADING_FILE: 'Trwa ładowanie pliku audio',
      PLAY_PAUSE: 'Play / Pause',
    },
  },
  DOCS: {
    TITLE: 'Dokumenty',
    DESCRIPTION: 'W poniższej tabeli znajdziesz informacje o dokumentach prawnych dostępnych w moim serwisie. Dokumenty te zawierają informacje o sposobie działania niniejszej witryny.',
  },
  LEVELS: {
    0: 'Koder Nowicjusz',
    1: 'Skrypter Początkujący',
    2: 'Programista Praktykant',
    3: 'Kompilator Entuzjasta',
    4: 'Konstruktor Modułów',
    5: 'Architekt Algorytmów',
    6: 'Władca Pętli',
    7: 'Szaman Debugowania',
    8: 'Mistrz Bibliotek',
    9: 'Sędzia Kodu',
    10: 'Mistrz Polimorfizmu',
    11: 'Czarodziej Interfejsów',
    12: 'Inżynier Skalowalności',
    13: 'Guru Architektury',
    14: 'Mistrz Programowania',
  },
}

export default pl;
